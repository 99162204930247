import React, { useEffect } from 'react';

import { Grid } from 'antd';
import { block } from 'bem-cn';
import { useNavigate } from 'react-router-dom';

import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { Modal } from '@src/components/atoms/modal';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { useSignInMutation } from '@src/store/queries/auth';
import { Heading2, Heading4 } from '@src/theme';
import { login } from '@src/theme/assets/animations';
import { NOTIFICATION_TYPES } from '@src/utils/constants';
import { saveStringToLocalStorage } from '@src/utils/local-storage';
import { lottieDefaultOptions } from '@src/utils/settings';

import { LoginForm } from './login-form';
import { StyledLoginModal } from './styles';

const b = block('login-modal');

const LoginModal = ({ isOpen, onOk, onClose }) => {
  const api = useNotificationContext();
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [signIn, { data: signInData, isLoading: isLoadingSignIn, isError: isErrorSignIn, error: signInError }] =
    useSignInMutation();

  useEffect(() => {
    if (!isErrorSignIn && signInData) {
      saveStringToLocalStorage({ key: 'accessToken', value: signInData.access_token });
      navigate('/user/home');
      onClose();
    } else if (isErrorSignIn) {
      let error = 'An error occurred while processing your request. Please try again later.';

      if (signInError.status == 401) {
        error = 'Invalid email or password';
      }

      api[NOTIFICATION_TYPES.error]({ message: error, pauseOnHover: true });
    }
  }, [api, isErrorSignIn, navigate, onClose, signInData, signInError]);

  return (
    <Modal
      className="login-modal-container p-0"
      isOpen={isOpen}
      onOk={onOk}
      onCancel={onClose}
      footer={null}
      width="90%"
    >
      <StyledLoginModal>
        <div className={b()}>
          <div className={b('body')}>
            {screens.md && (
              <div className={b('body', 'left-column')}>
                <div className={b('body', 'left-column', 'title')}>
                  <Heading4>Welcome Back</Heading4>
                </div>
                <div className={b('body', 'left-column', 'animation')}>
                  <LottiePlayer animationData={login} options={lottieDefaultOptions} />
                </div>
              </div>
            )}
            <div className={b('body', 'right-column')}>
              {!screens.md && (
                <div className={b('body', 'right-column', 'animation')}>
                  <LottiePlayer animationData={login} options={lottieDefaultOptions} />
                </div>
              )}

              <Heading2>Sign In</Heading2>
              <LoginForm loading={isLoadingSignIn} onSignIn={signIn} />
            </div>
          </div>
        </div>
      </StyledLoginModal>
    </Modal>
  );
};

export default LoginModal;
