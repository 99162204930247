import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { MenuIcon } from '@src/components/atoms/icons/menu-icon';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { LoginModal } from '@src/components/organisms/login-modal';
import { SideMenu } from '@src/components/organisms/side-menu';
import SingleLogo from '@src/theme/assets/logo/logo-horizontal.png';
import { bemClassName } from '@src/utils/bem';

import { StyledTopMenu } from './styles';

const b = bemClassName('top-menu');

const TopMenu = () => {
  const navigate = useNavigate();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  const goToOnboarding = useCallback(() => {
    navigate('/onboarding');
  }, [navigate]);

  const openSideMenu = useCallback(() => {
    setIsOpenSideMenu(true);
  }, []);

  const closeSideMenu = useCallback(() => {
    setIsOpenSideMenu(false);
  }, []);

  const openLoginModal = useCallback(() => {
    setIsOpenLoginModal(true);
  }, []);

  const closeLoginModal = useCallback(() => {
    setIsOpenLoginModal(false);
  }, []);

  return (
    <>
      <StyledTopMenu>
        <div className={b()}>
          <div className={b('logo-container')}>
            <img src={SingleLogo} />
          </div>
          <div className={b('menu-container')}>
            <ul className={b('menu-container', 'list')}>
              <li className={b('menu-container', 'list', 'item')}>
                <GradientButton onClick={openLoginModal}>SIGN IN</GradientButton>
              </li>
              <li className={b('menu-container', 'list', 'item')}>
                <GradientButton onClick={goToOnboarding}>SIGN UP</GradientButton>
              </li>
              <li className={b('menu-container', 'list', 'item')}>
                <MenuIcon className={b('menu-container', 'menu-button')} onClick={openSideMenu} />
              </li>
            </ul>
          </div>
        </div>
      </StyledTopMenu>
      <SideMenu isOpen={isOpenSideMenu} onDismiss={closeSideMenu} />
      <LoginModal isOpen={isOpenLoginModal} onClose={closeLoginModal} />
    </>
  );
};

export default TopMenu;
