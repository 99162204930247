import styled from 'styled-components';

export const StyledHomeRecentPosts = styled.div`
  padding: 120px 0 80px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  .home-recent-posts {
    width: 100%;
    max-width: 1200px;
    padding: 0 32px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 300px;

    &__left {
      position: relative;
      width: 320px;
      padding: 10px;

      &__title-static {
        position: absolute;
        height: 100%;
        display: block;
        padding-right: 16px;

        &__wrap {
          position: sticky;
          top: 100px;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          margin: auto;

          &__area {
            padding-bottom: 30px;

            p {
              padding-bottom: 16px;
            }
          }
        }
      }
    }

    &__right {
      width: calc(100% - 320px);
      padding: 10px;

      &__item {
        padding: 10px;
      }
    }
  }

  @media (max-width: 1000px) {
    .home-recent-posts {
      padding: 0 20px;

      &__right {
        padding: 10px 0 10px 10px;

        &__item {
          padding: 10px 0;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .home-recent-posts {
      &__left {
        width: 280px;
      }

      &__right {
        width: calc(100% - 280px);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0;

    .home-recent-posts {
      padding: 0 16px;

      &__left {
        width: 100%;

        &__title-static {
          position: relative;

          &__wrap {
            &__area {
              padding-bottom: 0px;
            }
          }
        }
      }

      &__right {
        width: 100%;
        padding: 10px 0;

        &__item {
          padding: 10px 0;
        }

        &__more-button {
          display: flex;
          justify-content: center;
          padding-top: 24px;
        }
      }
    }
  }
`;
