import React, { useEffect } from 'react';

import { Grid } from 'antd';
import block from 'bem-cn';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { GradientButton } from '@src/components/molecules/gradient-button';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { BlogCard } from '@src/components/organisms/blog-card';
import { useGetPostsQuery } from '@src/store/queries/posts';
import { Heading1, Heading2, Paragraph1 } from '@src/theme';
import { NOTIFICATION_TYPES } from '@src/utils/constants';

import { StyledHomeRecentPosts } from './styles';

const b = block('home-recent-posts');
const { useBreakpoint } = Grid;

const HomeRecentPosts = () => {
  const api = useNotificationContext();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const { data: postData, error: postsError } = useGetPostsQuery({ page: 1, limit: 3 });

  useEffect(() => {
    if (postsError) {
      api[NOTIFICATION_TYPES.error]({ message: 'An error occurred while loading the posts', pauseOnHover: true });
    }
  }, [api, postsError]);

  return (
    <StyledHomeRecentPosts>
      <div className={b()}>
        <div className={b('left')}>
          <div className={b('left', 'title-static')}>
            <div className={b('left', 'title-static', 'wrap')}>
              <div className={b('left', 'title-static', 'wrap', 'area')}>
                <Heading2> OUR BLOG POSTS</Heading2>
                <Heading1>
                  READ OUR <br /> LATEST STORIES
                </Heading1>
                <Paragraph1>
                  Welcome to our gym blog, where we share valuable insights, tips, and inspiration to help you on your
                  journey to a healthier...
                </Paragraph1>
              </div>

              {screens.md && (
                <GradientButton
                  onClick={() => {
                    navigate('/blog');
                  }}
                >
                  View More Posts
                </GradientButton>
              )}
            </div>
          </div>
        </div>
        <div className={b('right')}>
          {postData &&
            postData.data.map((post) => (
              <div key={`post-card-item-${post._id}`} className={b('right', 'item')}>
                <BlogCard
                  image={post.coverImageUrl}
                  tags={post.tags}
                  text={post.excerpt}
                  textLink={post.slug}
                  icon={<FaArrowRight />}
                  iconText={'Read More'}
                  iconTextLink={post.slug}
                />
              </div>
            ))}

          {!screens.md && (
            <div className={b('right', 'more-button')}>
              <GradientButton
                onClick={() => {
                  navigate('/blog');
                }}
              >
                View More Posts
              </GradientButton>
            </div>
          )}
        </div>
      </div>
    </StyledHomeRecentPosts>
  );
};

export default HomeRecentPosts;
