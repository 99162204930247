import React from 'react';

import block from 'bem-cn';

import { StyledUserHomePage } from './styles';

const b = block('user-home-page');

const UserHomePage = () => {
  return (
    <StyledUserHomePage>
      <div className={b()}>User Home Page</div>
    </StyledUserHomePage>
  );
};

export default UserHomePage;
