import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from '@src/components/molecules/notification-context';
import { FlipCountDown } from '@src/components/organisms/flip-count-down';
import { useSignUpMutation } from '@src/store/queries/auth';
import { useGetProfileQuery, useUpsertUserMutation } from '@src/store/queries/users';
import {
  selectOnboardingCurrentStep,
  selectOnboardingTotalSteps,
  selectOnboardingUserData,
  setOnboardingCurrentStep,
  setOnboardingUserData,
} from '@src/store/slices';
import { Heading2, Heading4, Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';
import { NOTIFICATION_TYPES } from '@src/utils/constants';
import { saveStringToLocalStorage } from '@src/utils/local-storage';

import { HealthFitnessGoalsForm } from './health-fitness-goals-form';
import { HealthInformationForm } from './health-information-form';
import { LifeStyleHabitsForm } from './life-style-habits-form';
import { MetricsForm } from './metrics-form';
import { PersonalInformationForm } from './personal-information-form';
import { StyledOnboardingPage } from './styles';

const b = bemClassName('onboarding-page');

const onboardingStepsComponents = {
  1: { title: 'Personal Information', Component: PersonalInformationForm },
  2: { title: 'Metrics', Component: MetricsForm },
  3: { title: 'Health And Fitness Goals', Component: HealthFitnessGoalsForm },
  4: { title: 'Lifestyle and Habits', Component: LifeStyleHabitsForm },
  5: { title: 'Health Information', Component: HealthInformationForm },
};

const OnboardingPage = () => {
  const api = useNotificationContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flipCountDownRef = useRef();

  const onboardingUserData = useSelector(selectOnboardingUserData);
  const currentStep = useSelector(selectOnboardingCurrentStep);
  const totalSteps = useSelector(selectOnboardingTotalSteps);

  const { title, Component } = useMemo(() => onboardingStepsComponents[currentStep] || {}, [currentStep]);

  const { data: profileData, isError: isErrorProfile } = useGetProfileQuery();
  const [upsertUser, { data: upsertUserData, isLoading: isLoadingUpsert, isError: isErrorUpsertUser }] =
    useUpsertUserMutation();
  const [signUp, { data: registrationData, isLoading: isLoadingSignUp, isError: isErrorSignUp }] = useSignUpMutation();

  useEffect(() => {
    if (!isErrorProfile && !isErrorUpsertUser && profileData) {
      if (profileData.onboarding.finished) {
        navigate('/home');
      } else {
        const initUserState = {
          id: profileData.id,
          name: profileData.name,
          email: profileData.email,

          height: profileData.height ?? 175,
          weight: profileData.weight ?? 75,

          dietaryPreferences: profileData.onboarding.healthAndFitnessGoals?.dietaryPreferences ?? [],
          fitnessPreferences: profileData.onboarding.healthAndFitnessGoals?.fitnessPreferences ?? [],
          primaryGoal: profileData.onboarding.healthAndFitnessGoals?.primaryGoal ?? '',

          currentActivityLevel: profileData.onboarding.currentLifestyleAndHabits?.currentActivityLevel ?? 0,
          waterIntake: profileData.onboarding.currentLifestyleAndHabits?.waterIntake ?? 0,
          sleepPatterns: profileData.onboarding.currentLifestyleAndHabits?.sleepPatterns ?? 0,

          medicalConditions: profileData.onboarding.healthInformation?.medicalConditions ?? [],
          medicalConditionsOther: profileData.onboarding.healthInformation?.medicalConditionsOther ?? '',
          allergies: profileData.onboarding.healthInformation?.allergies ?? [],
          allergiesOther: profileData.onboarding.healthInformation?.allergiesOther ?? '',
        };

        dispatch(setOnboardingCurrentStep(profileData.onboarding.onboardingStep));
        dispatch(
          setOnboardingUserData({
            ...onboardingUserData,
            ...initUserState,
          }),
        );
        flipCountDownRef.current.triggerTick(profileData.onboarding.onboardingStep);
      }
    } else if (isErrorProfile) {
      dispatch(setOnboardingCurrentStep(1));
      flipCountDownRef.current.triggerTick(1);
    }
  }, [dispatch, isErrorProfile, isErrorUpsertUser, isErrorUpsertUser, navigate, profileData]);

  useEffect(() => {
    if (!isErrorSignUp && registrationData) {
      api[NOTIFICATION_TYPES.success]({ message: 'Usuario registrado, complete el onboarding', pauseOnHover: true });
      dispatch(setOnboardingCurrentStep(2));
      dispatch(
        setOnboardingUserData({
          ...onboardingUserData,
          id: registrationData.data.id,
          name: registrationData.data.name,
          email: registrationData.data.email,
        }),
      );
      saveStringToLocalStorage({ key: 'accessToken', value: registrationData.access_token });
      flipCountDownRef.current.triggerTick(2);
    } else if (isErrorSignUp) {
      api[NOTIFICATION_TYPES.error]({ message: 'Ocurrió un problema en el registro', pauseOnHover: true });
    }
  }, [api, dispatch, isErrorSignUp, registrationData]);

  useEffect(() => {
    if (!isErrorUpsertUser && upsertUserData) {
      if (upsertUserData.onboarding.finished) {
        navigate('/home');
      } else {
        dispatch(setOnboardingCurrentStep(upsertUserData.onboarding.onboardingStep));
        flipCountDownRef.current.triggerTick(upsertUserData.onboarding.onboardingStep);
      }
    } else if (isErrorUpsertUser) {
      api[NOTIFICATION_TYPES.error]({
        message: 'Ocurrió un problema al actualizar los datos del usuario',
        pauseOnHover: true,
      });
    }
  }, [api, dispatch, isErrorUpsertUser, navigate, upsertUserData]);

  const handleStepFormSubmit = useCallback(
    async (values) => {
      dispatch(setOnboardingUserData({ ...onboardingUserData, ...values }));

      if (currentStep == 1) {
        await signUp(values);
      } else {
        const auxOnboardingUserData = { ...onboardingUserData, ...values };
        const upsertUserDto = {
          email: auxOnboardingUserData.email,
          height: auxOnboardingUserData.height,
          weight: auxOnboardingUserData.weight,
          onboarding: {
            finished: false,
            onboardingStep: currentStep,
            healthAndFitnessGoals: {
              primaryGoal: auxOnboardingUserData.primaryGoal,
              dietaryPreferences: auxOnboardingUserData.dietaryPreferences,
              fitnessPreferences: auxOnboardingUserData.fitnessPreferences,
            },
            currentLifestyleAndHabits: {
              currentActivityLevel: auxOnboardingUserData.currentActivityLevel,
              waterIntake: auxOnboardingUserData.waterIntake,
              sleepPatterns: auxOnboardingUserData.sleepPatterns,
            },
            healthInformation: {
              medicalConditions: auxOnboardingUserData.medicalConditions,
              medicalConditionsOther: auxOnboardingUserData.medicalConditionsOther,
              allergies: auxOnboardingUserData.allergies,
              allergiesOther: auxOnboardingUserData.allergiesOther,
            },
          },
        };

        await upsertUser({ userId: onboardingUserData.id, upsertUserDto });
      }
    },
    [currentStep, dispatch, onboardingUserData, signUp, upsertUser],
  );

  const handleStepBack = useCallback(async () => {
    const auxOnboardingUserData = { ...onboardingUserData };
    const upsertUserDto = {
      email: auxOnboardingUserData.email,
      height: auxOnboardingUserData.height,
      weight: auxOnboardingUserData.weight,
      onboarding: {
        finished: false,
        onboardingStep: currentStep - 2,
        healthAndFitnessGoals: {
          primaryGoal: auxOnboardingUserData.primaryGoal,
          dietaryPreferences: auxOnboardingUserData.dietaryPreferences,
          fitnessPreferences: auxOnboardingUserData.fitnessPreferences,
        },
        currentLifestyleAndHabits: {
          currentActivityLevel: auxOnboardingUserData.currentActivityLevel,
          waterIntake: auxOnboardingUserData.waterIntake,
          sleepPatterns: auxOnboardingUserData.sleepPatterns,
        },
        healthInformation: {
          medicalConditions: auxOnboardingUserData.medicalConditions,
          medicalConditionsOther: auxOnboardingUserData.medicalConditionsOther,
          allergies: auxOnboardingUserData.allergies,
          allergiesOther: auxOnboardingUserData.allergiesOther,
        },
      },
    };

    await upsertUser({ userId: onboardingUserData.id, upsertUserDto });
  }, [currentStep, onboardingUserData, upsertUser]);

  return (
    <StyledOnboardingPage>
      <div className={b()}>
        <div className={b('heading')}>
          <Heading2>Onboarding</Heading2>
          <Paragraph1>
            Welcome to our fitness and weight loss platform! To provide you with a personalized experience, we&apos;ll
            collect only the essential information. This helps us tailor our services to fit your unique needs and
            preferences. Our onboarding process is quick and straightforward, ensuring we gather just enough data to
            customize your diet plans, workout routines, and other wellness activities. With your input, we can deliver
            the best advice and support to help you achieve your fitness goals and enjoy a healthier lifestyle.
            Let&apos;s get started on this journey together!
          </Paragraph1>
          {currentStep <= totalSteps && <FlipCountDown ref={flipCountDownRef} initialNumber={1} />}
        </div>

        {currentStep <= totalSteps && (
          <div className={b('form')}>
            {title && (
              <div className={b('form', 'title')}>
                <Heading4>{title}</Heading4>
              </div>
            )}
            {Component && (
              <Component
                onSubmit={handleStepFormSubmit}
                onBack={handleStepBack}
                loading={isLoadingUpsert || isLoadingSignUp}
              />
            )}
          </div>
        )}
      </div>
    </StyledOnboardingPage>
  );
};

export default OnboardingPage;
