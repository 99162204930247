import React, { useCallback, useState } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';

import { FormInput } from '@src/components/atoms/form/form-input';
import { OptionPicker } from '@src/components/atoms/form/option-picker';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { selectOnboardingUserData } from '@src/store/slices';
import { bemClassName } from '@src/utils/bem';
import { HEALTH_INFORMATION } from '@src/utils/constants';

import { StyledHealthInformationForm } from './styles';

const b = bemClassName('health-information-form');

const HealthInformationForm = ({ onSubmit, onBack, loading }) => {
  const [form] = Form.useForm();
  const onboardingUserData = useSelector(selectOnboardingUserData);

  const [showMedCondOther, setShowMedCondOther] = useState(onboardingUserData.medicalConditionsOther != '');
  const [showAllergiesOther, setShowAllergiesOther] = useState(onboardingUserData.allergiesOther != '');

  const onFinish = useCallback(
    (values) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <StyledHealthInformationForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        defaultValue={onboardingUserData}
        initialValues={onboardingUserData}
      >
        <div className={b('item')}>
          <OptionPicker
            label="Medical Conditions"
            question="Do you have any medical conditions that we should be aware of to provide you with safe and suitable recipes?"
            name="medicalConditions"
            form={form}
            options={HEALTH_INFORMATION.medicalConditions}
            multiple
            showOther
            hasOther={showMedCondOther}
            onOther={setShowMedCondOther}
            showNone
            onNone={() => {
              setShowMedCondOther(false);
              form.setFieldsValue({ medicalConditionsOther: '' });
            }}
          />
        </div>

        {showMedCondOther && (
          <div className={b('item')}>
            <FormInput
              name="medicalConditionsOther"
              label="Other Medical Condition"
              rules={[{ required: true, message: 'Please input your other medical condition!' }]}
              form={form}
            />
          </div>
        )}

        <div className={b('item')}>
          <OptionPicker
            label="Allergies"
            question="Do you have any food allergies?"
            name="allergies"
            form={form}
            options={HEALTH_INFORMATION.allergies}
            multiple
            showOther
            hasOther={showAllergiesOther}
            onOther={setShowAllergiesOther}
            showNone
            onNone={() => {
              setShowAllergiesOther(false);
              form.setFieldsValue({ allergiesOther: '' });
            }}
          />
        </div>

        {showAllergiesOther && (
          <div className={b('item')}>
            <FormInput
              name="allergiesOther"
              label="Other Allergy"
              rules={[{ required: true, message: 'Please input your other allergy!' }]}
              form={form}
            />
          </div>
        )}

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} loading={loading} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading}>
              FINISH
            </GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledHealthInformationForm>
  );
};

export default HealthInformationForm;
