import React from 'react';

import { Form } from 'antd';
import { block } from 'bem-cn';
import { FaEnvelope, FaShieldAlt } from 'react-icons/fa';

import { FormInput } from '@src/components/atoms/form/form-input';
import { GradientButton } from '@src/components/molecules/gradient-button';

import { StyledLoginForm } from './styles';

const b = block('login-form');

const LoginForm = ({ onSignIn, loading }) => {
  const [form] = Form.useForm();

  return (
    <StyledLoginForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onSignIn}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        <div className={b('item')}>
          <FormInput
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
            icon={<FaEnvelope />}
            form={form}
          />
        </div>
        <div className={b('item')}>
          <FormInput
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            icon={<FaShieldAlt />}
            isPassword
            form={form}
          />
        </div>
        <div className={b('submit')}>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading} width="auto">
              CONTINUE
            </GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledLoginForm>
  );
};

export default LoginForm;
