import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { NotificationProvider } from './components/molecules/notification-context';
import MainRoutes from './MainRoutes';
import { store } from './store';
import { Theme } from './theme';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <NotificationProvider>
          <Theme>
            <MainRoutes />
          </Theme>
        </NotificationProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
