import styled from 'styled-components';

const getImageWidth = (width) => {
  if (!width) {
    return '300px';
  } else if (typeof width === 'string') {
    return width;
  } else if (typeof width === 'number') {
    return `${width}px`;
  }

  return 'auto';
};

const getImageHeight = (height) => {
  if (!height) {
    return '220px';
  } else if (typeof height === 'string') {
    return height;
  } else if (typeof height === 'number') {
    return `${height}px`;
  }

  return 'auto';
};

export const StyledHoverScaleImage = styled.div`
  width: ${({ width }) => getImageWidth(width)};
  height: ${({ height }) => getImageHeight(height)};
  border-radius: 20px;
  overflow: hidden;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    overflow: clip;
    transition: 0.4s ease-in-out;

    &:hover {
      transform: scale(1.03) translateZ(0);
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    height: auto;
  }
`;
