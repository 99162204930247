import React, { useCallback, useEffect, useState } from 'react';

import block from 'bem-cn';
import { FaArrowRight } from 'react-icons/fa';

import { LoadingIndicator } from '@src/components/atoms/loading-indicator';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { SearchForm } from '@src/components/molecules/search-form';
import { SimpleRoundedPaginator } from '@src/components/molecules/simple-rounded-paginator';
import { TagsCloud } from '@src/components/molecules/tags-cloud';
import { BlogCard } from '@src/components/organisms/blog-card';
import { BlogCategoriesList } from '@src/components/organisms/blog-categories-list';
import { useGetPostsQuery } from '@src/store/queries/posts';
import { Paragraph7, variables } from '@src/theme';
import { NOTIFICATION_TYPES } from '@src/utils/constants';

import { categoriesData, tagsData } from './blogData';
import { StyledBlogPage } from './styles';

const b = block('blog-page');

const BlogPage = () => {
  const api = useNotificationContext();
  const [filter, setFilter] = useState({ limit: 10 });

  const { data: postData, isLoading: postsLoading, error: postsError } = useGetPostsQuery(filter);

  useEffect(() => {
    if (postsError) {
      api[NOTIFICATION_TYPES.error]({ message: 'An error occurred while loading the posts', pauseOnHover: true });
    }
  }, [api, postsError]);

  const paginatorHandler = useCallback(
    (activePage) => {
      setFilter({ ...filter, page: activePage });
    },
    [filter],
  );

  return (
    <StyledBlogPage>
      <div className={b()}>
        <div className={b('left')}>
          {postsLoading && <LoadingIndicator size={16} />}
          {postData &&
            postData.data.map((post) => (
              <div key={`blog-card-item-${post._id}`} className={b('content', 'left', 'item')}>
                <BlogCard
                  image={post.coverImageUrl}
                  tags={post.tags}
                  text={post.excerpt}
                  textLink={post.slug}
                  icon={<FaArrowRight />}
                  iconText={'Read More'}
                  iconTextLink={post.slug}
                />
              </div>
            ))}
          {!postData && <Paragraph7>No posts data found...</Paragraph7>}

          {postData && (
            <div className={b('left', 'pagination')}>
              <SimpleRoundedPaginator
                pages={postData.totalPages}
                activePage={postData.currentPage}
                backgroundColor={variables.colors.athensGray}
                onPageChange={paginatorHandler}
              />
            </div>
          )}
        </div>
        <div className={b('right')}>
          <SearchForm />
          <BlogCategoriesList title={'Categories'} categories={categoriesData} />
          <TagsCloud title={'Popular Tags'} tags={tagsData} />
        </div>
      </div>
    </StyledBlogPage>
  );
};

export default BlogPage;
