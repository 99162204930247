import styled from 'styled-components';

export const StyledUserHomePage = styled.div`
  width: 100%;

  .user-home-page {
    width: 100%;
    max-width: 1400px;
    gap: 24px;
    padding: 24px;
    display: flex;
    margin: 60px auto;
  }
`;
