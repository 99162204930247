import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Divider, Form } from 'antd';
import { FaCheck } from 'react-icons/fa';

import { Heading17, Heading2, Paragraph2 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledOptionPicker } from './styles';

const b = bemClassName('options-picker');

const OptionPicker = ({
  label,
  question,
  name,
  form,
  options,
  multiple,
  required,
  showOther,
  hasOther,
  onOther,
  showNone,
  onNone,
}) => {
  const [value, setValue] = useState(multiple ? [] : '');
  const [other, setOther] = useState(hasOther);
  const [none, setNone] = useState(false);

  const handleValueChange = useCallback(
    (e) => {
      e.stopPropagation();
      setNone(false);

      if (multiple) {
        const index = value?.indexOf(e.target.value);

        if (index === -1) {
          const aux = [...value, e.target.value];
          setValue(aux);
          form.setFieldsValue({ [name]: aux });
        } else {
          const aux = value.filter((item) => item !== e.target.value);
          setValue(aux);
          form.setFieldsValue({ [name]: aux });
        }
      } else {
        setValue(e.target.value);
        form.setFieldsValue({ [name]: e.target.value });
      }
    },
    [form, multiple, name, value],
  );

  const handleToggleOther = useCallback(
    (e) => {
      e.stopPropagation();
      setNone(false);
      setOther(!other);
      onOther(!other);
    },
    [onOther, other],
  );

  const handleToggleNone = useCallback(
    (e) => {
      e.stopPropagation();
      setOther(false);
      onOther(false);
      onNone();

      setNone(!none);

      if (!none) {
        if (multiple) {
          setValue([]);
          form.setFieldsValue({ [name]: [] });
        } else {
          setValue('');
          form.setFieldsValue({ [name]: '' });
        }
      }
    },
    [form, multiple, name, none, onNone, onOther],
  );

  useEffect(() => {
    setValue(form.getFieldValue(name));
  }, [form, name]);

  return (
    <StyledOptionPicker>
      <div className={b()}>
        {label && (
          <div className={b('label')}>
            <Divider orientation="left">
              <Heading2>{label}</Heading2>
            </Divider>
          </div>
        )}
        {question && (
          <div className={b('question')}>
            <Paragraph2>{question}</Paragraph2>
          </div>
        )}
        <Form.Item
          name={name}
          rules={[
            {
              required,
              message: <Alert type="error" message="Please provide this information to enhance your experience" />,
            },
          ]}
        >
          <div className={b('options')}>
            {showNone && (
              <label className={b('options', 'item')}>
                <input
                  type="checkbox"
                  id={`${name}-none`}
                  value={`${name}-none`}
                  onChange={handleToggleNone}
                  checked={none}
                />
                <div className={b('options', 'item', 'box')}>
                  <Heading17 className={b('options', 'item', 'box', 'icon')}>
                    <FaCheck />
                  </Heading17>
                  <span>
                    <Heading17>None</Heading17>
                  </span>
                </div>
              </label>
            )}
            {options.map((option) => (
              <label key={`option-item-${option.value}`} className={b('options', 'item')}>
                <input
                  type="checkbox"
                  id={option.value}
                  value={option.value}
                  onChange={handleValueChange}
                  checked={multiple ? value.indexOf(option.value) >= 0 : value === option.value}
                />
                <div className={b('options', 'item', 'box')}>
                  <Heading17 className={b('options', 'item', 'box', 'icon')}>
                    <FaCheck />
                  </Heading17>
                  <span>
                    <Heading17>{option.text}</Heading17>
                  </span>
                </div>
              </label>
            ))}
            {showOther && (
              <label className={b('options', 'item')}>
                <input
                  type="checkbox"
                  id={`${name}-other`}
                  value={`${name}-other`}
                  onChange={handleToggleOther}
                  checked={other}
                />
                <div className={b('options', 'item', 'box')}>
                  <Heading17 className={b('options', 'item', 'box', 'icon')}>
                    <FaCheck />
                  </Heading17>
                  <span>
                    <Heading17>{'Other'}</Heading17>
                  </span>
                </div>
              </label>
            )}
          </div>
        </Form.Item>
      </div>
    </StyledOptionPicker>
  );
};

export default OptionPicker;
