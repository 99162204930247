import styled from 'styled-components';

export const Heading1 = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2rem;
  font-weight: 900;
`;

export const Heading2 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 0.8rem;
  font-weight: 900;
`;

export const Heading3 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 1.2rem;
  font-weight: 700;
`;

export const Heading4 = styled.p`
  font-family: 'Sports College';
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 2rem;
  font-weight: 900;
`;

export const Heading5 = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.75rem;
  font-weight: 900;
`;

export const Heading6 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 1rem;
  font-weight: 400;
`;

export const Heading7 = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.2rem;
  font-weight: 600;
`;

export const Heading8 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 0.7rem;
  font-weight: 900;
`;

export const Heading9 = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 500;
`;

export const Heading10 = styled.p`
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  font-size: 1.375rem;
  font-weight: 500;
`;

export const Heading11 = styled.p`
  color: ${({ theme }) => theme.colors.mainBlue};
  font-weight: 700;
  font-size: 1.2rem;
`;

export const Heading12 = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.875rem;
  font-weight: 500;
`;

export const Heading13 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 4.5rem;
  font-weight: 500;
`;

export const Heading14 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 1.5rem;
`;

export const Heading15 = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.375rem;
  font-weight: 500;
`;

export const Heading16 = styled.p`
  color: ${({ theme }) => theme.colors.slateGray};
  font-size: 2rem;
  font-weight: 900;
`;

export const Heading17 = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

export const Heading18 = styled.p`
  color: ${({ theme }) => theme.colors.mainBlue};
  font-size: 2rem;
  font-weight: 900;
`;

export const Heading19 = styled.p`
  font-weight: 500;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.codGray};
`;

export const Paragraph1 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 0.9rem;
  line-height: 1.5;
`;

export const Paragraph2 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 1rem;
  line-height: 1.5;
`;

export const Paragraph3 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 0.875rem;
  line-height: 2;
`;

export const Paragraph4 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
`;

export const Paragraph5 = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
`;

export const Paragraph6 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 0.7rem;
  font-weight: 600;
`;

export const Paragraph7 = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.codGray};
  line-height: 30px;
`;

export const Paragraph8 = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.codGray};
`;

export const Paragraph9 = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.raven};
  line-height: 34px;
`;

export const Paragraph10 = styled.p`
  font-family: 'Sports College';
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 1rem;
  font-weight: 300;
`;

export const Paragraph11 = styled.p`
  color: ${({ theme }) => theme.colors.mainOrange};
  font-size: 0.85rem;
  font-weight: 500;
`;

export const TextLink1 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.mainOrange};
  }
`;

export const TextLink2 = styled.p`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.mainOrange};
  }
`;

export const TextLink4 = styled.p`
  font-weight: 500;
  font-size: 1.375rem;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.mainOrange};
  }
`;

export const Label1 = styled.label`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.wildSand};
`;

export const Label2 = styled.label`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mainBlue};
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
`;

export const Label3 = styled.label`
  font-size: 0.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mainBlue};
`;

export const Label4 = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.codGray};
`;

export const Text1 = styled.text`
  font-size: 1.2rem;
  stroke: ${({ theme }) => theme.colors.mainBlue};
`;

export const TextInput1 = styled.input`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mainOrange};
`;
