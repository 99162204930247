import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 1,
  totalSteps: 5,
  userData: {
    id: '',
    name: '',
    email: '',
    password: '',

    height: 175,
    weight: 75,

    dietaryPreferences: [],
    fitnessPreferences: [],
    primaryGoal: '',

    currentActivityLevel: 0,
    waterIntake: 0,
    sleepPatterns: 0,

    medicalConditions: [],
    medicalConditionsOther: '',
    allergies: [],
    allergiesOther: '',
  },
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState,
  reducers: {
    setOnboardingCurrentStep: (state, action) => {
      state.currentStep = action.payload ?? state.currentStep;
    },
    setOnboardingTotalSteps: (state, action) => {
      state.totalSteps = action.payload ?? state.totalSteps;
    },
    resetOnboarding: (state) => {
      state.currentStep = initialState.currentStep;
      state.totalSteps = initialState.totalSteps;
      state.userData = initialState.userData;
    },
    setOnboardingUserData: (state, action) => {
      state.userData = action.payload || state.userData;
    },
  },
  selectors: {
    selectOnboardingCurrentStep: ({ currentStep }) => currentStep,
    selectOnboardingTotalSteps: ({ totalSteps }) => totalSteps,
    selectOnboardingRemainingSteps: ({ totalSteps, currentStep }) => totalSteps - currentStep,
    selectOnboardingProgress: ({ totalSteps, currentStep }) => (totalSteps > 0 ? (currentStep / totalSteps) * 100 : 0),
    selectOnboardingUserData: ({ userData }) => userData,
  },
});

export const { setOnboardingCurrentStep, setOnboardingTotalSteps, resetOnboarding, setOnboardingUserData } =
  onboardingSlice.actions;

export const {
  selectOnboardingCurrentStep,
  selectOnboardingTotalSteps,
  selectOnboardingRemainingSteps,
  selectOnboardingProgress,
  selectOnboardingUserData,
} = onboardingSlice.selectors;

export default onboardingSlice.reducer;
