import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

const AuthenticatedRoute = () => {
  // TODO: Replace with actual authentication check
  const isAuthenticated = true;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticatedRoute;
