import { configureStore } from '@reduxjs/toolkit';

import { authApi } from './queries/auth';
import { postsApi } from './queries/posts';
import { usersApi } from './queries/users';
import { onboardingReducer } from './slices';

export const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    [authApi.reducerPath]: authApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware).concat(postsApi.middleware).concat(usersApi.middleware),
  devTools: {
    predicate: (_, { type, error }) => {
      if (error) {
        const { name, message } = error;

        if (
          type.includes('rejected') &&
          name === 'ConditionError' &&
          message === 'Aborted due to condition callback returning false.'
        ) {
          return false;
        }
      }

      return true;
    },
  },
});
