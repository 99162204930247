import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { MainLayout } from '@src/layouts/main';
import { OnboardingLayout } from '@src/layouts/onboarding';
import { AboutPage } from '@src/pages/about';
import { BlogPage } from '@src/pages/blog';
import { HomePage } from '@src/pages/home';
import { LandingPage } from '@src/pages/landing';
import { OnboardingPage } from '@src/pages/onboarding';
import { PricingPage } from '@src/pages/pricing';
import { UserHomePage } from '@src/pages/user/home';

import '@src/theme/styles/style.scss';
import '@src/theme/styles/antd-overrides.scss';
import { AuthenticatedRoute } from './components/molecules/auth';

const MainRoutes = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route element={<MainLayout />}>
      <Route path="home" element={<HomePage />} />
      <Route path="pricing" element={<PricingPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="blog" element={<BlogPage />} />
      <Route path="user" element={<AuthenticatedRoute />}>
        <Route index element={<UserHomePage />} />
        <Route path="home" element={<UserHomePage />} />
      </Route>
    </Route>
    <Route element={<OnboardingLayout />}>
      <Route path="onboarding" element={<OnboardingPage />} />
    </Route>
  </Routes>
);

export default MainRoutes;
